<template>
    <div>
        <v-pagination v-model="page" :length="paginationPages" class="my-4">
        </v-pagination>

        <v-simple-table>
            <thead>
                <tr>
                    <th>Tunniste</th>
                    <th>Hoitoryhmä</th>
                    <th>Lisätty</th>
                    <th v-if="hasAdder">
                        Lisääjä
                    </th>
                    <th class="text-right">
                        <v-btn
                            title="Päivitä luettelo"
                            color="primary"
                            @click="fetchHistory(true)"
                            :loading="loading"
                        >
                            Päivitä
                        </v-btn>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="patient in paginatedHistory" :key="patient.id">
                    <td>{{ patient.id }}</td>
                    <td>
                        {{ patient.group === null ? "—" : patient.group + 1 }}
                    </td>
                    <td>{{ patient.created_at }}</td>
                    <td v-if="hasAdder">
                        {{ patient.adder.name }}
                    </td>
                    <td class="text-right">
                        <v-btn
                            text
                            color="primary"
                            :to="{
                                name: 'show-patient',
                                params: { id: patient.id }
                            }"
                        >
                            Näytä
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination v-model="page" :length="paginationPages" class="mt-4">
        </v-pagination>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            loading: false,
            page: 1,
            items: 20,
            history: []
        };
    },

    methods: {
        fetchHistory(notify = false) {
            this.loading = true;
            this.$http
                .get(`/api/patients`)
                .then(response => (this.history = response.data))
                .then(() => (this.loading = false))
                .then(() => {
                    if (notify) {
                        this.$dialog.notify.info("Lista päivitettiin", {
                            position: "bottom-right"
                        });
                    }
                });
        }
    },

    computed: {
        ...mapGetters(["isLoggedIn", "isAdmin"]),
        hasAdder() {
            return this.history.length > 0 && this.history[0].adder;
        },

        paginatedHistory() {
            const start = (this.page - 1) * this.items;
            const end = this.page * this.items;

            return this.history.slice(start, end);
        },

        paginationPages() {
            return Math.ceil(this.history.length / this.items);
        }
    },

    created() {
        this.fetchHistory();
    }
};
</script>

<style scoped></style>
