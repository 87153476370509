export default [{
        id: "1",
        text: "Ei oireita"
    },
    {
        id: "2a",
        text: "Lieviä oireita, oireet eivät vaivaa potilasta tai vaikuta päivittäiseen elämään"
    },
    {
        id: "2b",
        text: "Lieviä oireita, oireet vaivaavat lievästi päivittäistä elämää"
    },
    {
        id: "3",
        text: "Vaikeita oireita, joiden takia päivittäinen elämä vaikeutuu"
    },
    {
        id: "4",
        text: "Sietämättömiä oireita, joiden takia päivittäistä elämää on pitänyt muuttaa"
    }
]
