<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Lisätty</th>
                    <th>Lisääjä</th>
                    <th>Ehra</th>
                    <th>Tarkempi syy</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="rejector in history" :key="rejector.id">
                    <td>{{ rejector.created_at }}</td>
                    <td>{{ rejector.adder.name }}</td>
                    <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span v-on="on" class="py-1 px-2">
                                    {{ rejector.ehra_class }}
                                </span>
                            </template>
                            {{ getEhraDescription(rejector.ehra_class) }}
                        </v-tooltip>
                    </td>
                    <td>{{ rejector.description }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import ehraReasons from "@/utils/ehra";

    export default {
        data() {
            return {
                loading: false,
                history: []
            };
        },

        methods: {
            fetchHistory(notify = false) {
                this.loading = true;
                this.$http
                    .get(`/api/rejectors`)
                    .then(response => (this.history = response.data))
                    .then(() => (this.loading = false))
                    .then(() => {
                        if (notify) {
                            this.$dialog.notify.info("Lista päivitettiin", {
                                position: "bottom-right"
                            });
                        }
                    });
            },

            getEhraDescription(ehraClass) {
                return ehraReasons.find(ehra => ehra.id === ehraClass).text;
            }
        },

        computed: {
            ...mapGetters(["isLoggedIn", "isAdmin"]),
            hasAdder() {
                return this.history.length > 0 && this.history[0].adder;
            }
        },

        created() {
            this.fetchHistory();
        }
    };
</script>

<style scoped>
</style>