<template>
    <div>
        <v-card>
            <v-card-title class="text-center justify-center py-md-5 my-4">
                <h1 class="text-center display-3">Historia</h1>
            </v-card-title>
            <v-tabs grow v-model="tab">
                <v-tab>Potilaat</v-tab>
                <v-tab>Kieltäytyneet</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <PatientHistoryTable />
                </v-tab-item>
                <v-tab-item>
                    <RejectorsHistoryTable />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import PatientHistoryTable from "@/components/history/patientsTable.vue";
    import RejectorsHistoryTable from "@/components/history/rejectorsTable.vue";

    export default {
        components: { PatientHistoryTable, RejectorsHistoryTable },
        data() {
            return {
                tab: null
            };
        }
    };
</script>

<style scoped>
</style>